import React from 'react'
import '../styles/Topheading.scss'

function Topheading({h1, h5 }) {
  return (
    <>
    <div className="topheading parent">
        <div className="topheading-container container">
            
            <div className="down">
                <h1>{h1}</h1>
            </div>

        </div>
    </div>




    </>
  )
}

export default Topheading