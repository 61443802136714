import React, { useState } from "react";
import "../styles/header.scss";
import { Link } from "react-router-dom";
import Logo from "../assets/Artboard.png";
import { IoReorderThreeOutline } from "react-icons/io5";
import { MdOutlineClose } from "react-icons/md";

function Header(props) {
  const [nav, setNav] = useState(false);

  return (
    <>
      {props.data.map((data) => {
        return (
          <>
            <div className="header-parent parent">
              <div className="header-container container">
                <Link to="/" className="header-logo-div">
                  <img src={Logo} alt="" className="h-logo" />
                 
                
                </Link>

                <Link
                    to="/"
                    onClick={() => setNav(false)}
                    className="header-logo-contain"
                  >
                    <div className="logo-text">
                       <h2> {data.Header_logo}</h2>
                       <p>{data.Header_subheading}</p>
                    
                    
                    </div>
                  </Link>
                <div className="nav-logo-div">
                  <IoReorderThreeOutline
                    className="logo-nav"
                    onClick={() => setNav(true)}
                  />
                </div>
              </div>
            </div>

            <div className={nav ? "nav-toggle active" : "nav-toggle"}>
              <Link to={"/"} onClick={() => setNav(false)}>
                {data.Header_home}
              </Link>
              <Link to={"/About"} onClick={() => setNav(false)}>
                {data.Header_about}
              </Link>
              <Link to={"/Features"} onClick={() => setNav(false)}>
                {data.Header_features}
              </Link>
              {/* <Link to={"/Residencial"} onClick={() => setNav(false)}>
                {data.Header_residential}
              </Link> */}
              <Link to={"/CBD"} onClick={() => setNav(false)}>
                {data.Header_cbd}
              </Link>
              <Link to={"/News"} onClick={() => setNav(false)}>
                {data.Header_news}
              </Link>
              <Link to={"/Contact"} onClick={() => setNav(false)}>
                {data.Header_contact}
              </Link>
              {/* <Link to="/Login">
          <button type="button" onClick={() => setNav(false)} className="btn-active">
            {data.Header_client}
          </button>
        </Link> */}

              <div className="close" onClick={() => setNav(false)}>
                <MdOutlineClose />
              </div>
            </div>

            <div className="pop" onClick={props.openPop}>
              <div className="bg-img-contain pop-icon"></div>
              <p className="pop-p">
                {data.Header_enquire}
                <br />
                {data.Header_now}
              </p>
            </div>
          </>
        );
      })}
    </>
  );
}

export default Header;
