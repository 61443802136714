import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Cbd from "../src/pages/Cbd";
import Features1 from "./pages/Features1";
import Home from "./pages/Home";
import Contact from "../src/pages/Contact";
import Residencial from "./pages/Residencial";
import News from "./pages/News";
import Login from "../src/pages/Login";
import About from "../src/pages/About";
import "swiper/css";
import "swiper/css/navigation";
import english from "./content/English";
import marathi from "./content/Marathi";
import "swiper/css/pagination";
import Togglebtn from "./components/maintool/Togglebtn";
import Pop from '../src/components/Pop'
import TermsAndCondition from "./pages/TermsAndCondition";
import NewsTemp from "./pages/NewsTemp";

function App() {
  const [language, setlanguage] = useState(marathi);
  const [toggle, settoggle] = useState(true);
  const toggleJsonData = () => {
    setlanguage(language === english ? marathi : english);
    settoggle(!toggle);
  };

  const [pop, setPop] = useState(false);

  function closePop(){
    setPop(false);
  }

  function openPop(){
    setPop(true);
  }


  return (
    <div className="App">
      <BrowserRouter>
        <Togglebtn toggle={toggleJsonData} />
        <Header openPop={openPop} data={language}/>
        {pop && <Pop closePop={closePop} data={language} />}

        <Routes>
          <Route path="/" element={<Home data={language} />} />
          <Route path="/About" element={<About data={language} />} />
          <Route path="/Features" element={<Features1 openPop={openPop} data={language} />} />
          <Route
            path="/Residencial"
            element={<Residencial data={language} />}
          />
          <Route path="/CBD" element={<Cbd openPop={openPop} data={language} />} />
          <Route path="/News" element={<News data={language} />} />
          <Route path="/Contact" element={<Contact data={language} />} />
          <Route path="/Login" element={<Login data={language} />} />
          <Route path="/terms" element={<TermsAndCondition />} />
          <Route path="/news/:id" element={<NewsTemp  />} />
        </Routes>

        <Footer data={language} />
      </BrowserRouter>
    </div>
  );
}

export default App;
