import React from "react";
import "../styles/Download.scss";

function Download(props) {
  return (
    <>
      {props.data.map((data) => {
        return (
          <>
            <div className="download parent">
              <div className="download-container container">
                <div className="content-box">
                  <h2>{data.Download_}</h2>

                  <div className="phases-box">
                    <div className="btn">
                      <button>{data.Download_city}</button>
                      <button>{data.Download_phase1}</button>
                      <button>{data.Download_phase2}</button>
                      <button>{data.Download_phase3}</button>
                      {/* <button>{data. Download_phase4}</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
}

export default Download;
