import React     from "react";
import "../styles/login.scss";
import { Link } from "react-router-dom";

function Login() {
  return (
    <>
      <div className="login-parent parent">
        <div className="login-container container">
          <h2>Client Login</h2>
          
          <div className="login-div-input">
            <input className="login-input" type="text" placeholder="UserName" />
            <input className="login-input" type="text" placeholder="Password" />
          </div>
          <div className="forgot-pass">
             <Link className="for-pass-span">Forgot Password?</Link>
          </div>
          <button className="btn-active">Login</button>
        </div>
      </div>
    </>
  );
}

export default Login;