import React from 'react'
import '../styles/Cbd.scss'
import Download from '../components/Download'
import Topheading from '../components/Topheading'
import List from '../components/List'
import img1 from "../Images/cbd/activity.jpg"
import img2 from '../Images/cbd/agriculture.jpg'
import img3 from '../Images/cbd/government.png'
import img4 from '../Images/cbd/ecosystem.jpg'
import img5 from '../Images/cbd/education.jpg'
import img6 from '../Images/cbd/shop.jpg'




function CBD(props) {
    return (
       <>
       {props.data.map((data) =>{
        return(
        <div className='cbd-bg'>
        {/* <div className="download-btn">
        <Download  data={props.data}/>
        </div> */}
            
           <div className="cbd-heading">
           <Topheading
                h5={data.cbdHeading}
                h1={data.cbd_h2} />
           </div>
              

            <List 
            open={props.openPop}
            data={props.data}
             img={img1}
             title={data. cbd_ul1_heading}
             li1={data.cbd_ul1_li1}
             li2={data.Cbd_ul1_li2}
             li3={data.Cbd_ul1_li3}
             li4={data.Cbd_ul1_li4}
             li5={data.Cbd_ul1_li5} 
            />  
            <List open={props.openPop}
            data={props.data}
            img={img2} 
             title={data. cbd_ul2_heading}
             li1={data.cbd_ul2_li1}
             li2={data.Cbd_ul2_li2}
             li3={data.Cbd_ul2_li3}
             li4={data.Cbd_ul2_li4}
             li5={data.Cbd_ul2_li5} />
              
            <List open={props.openPop}
            data={props.data}
            img={img3} 
             title={data. cbd_ul3_heading}
             li1={data.cbd_ul3_li1}
             li2={data.Cbd_ul3_li2}
             li3={data.Cbd_ul3_li3}
             li4={data.Cbd_ul3_li4}

             />  
            <List open={props.openPop}
            data={props.data}
            img={img6} 
             title={data. cbd_ul6_heading}
             li1={data.cbd_ul6_li1}
             li2={data.Cbd_ul6_li2}
             li3={data.Cbd_ul6_li3}
             li4={data.Cbd_ul6_li4} /> 

            <List open={props.openPop}
            data={props.data}
             img={img4} 
              title={data. cbd_ul4_heading}
              li1={data.cbd_ul4_li1}
              li2={data.Cbd_ul4_li2}
              li3={data.Cbd_ul4_li3}
              li4={data.Cbd_ul4_li4}/> 

            <List open={props.openPop} 
            data={props.data}
             img={img5} 
             title={data. cbd_ul5_heading}
            
             li1={data.cbd_ul5_li1}
             li2={data.Cbd_ul5_li2}
             li3={data.Cbd_ul5_li3}
             li4={data.Cbd_ul5_li4}
             li5={data.Cbd_ul5_li5}  />  

            

             


         







        </div>
        );
    })}
       </>
    );
}

export default CBD