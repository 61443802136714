
import React, { useEffect, useState } from "react";
import "../styles/NewsTemp.scss"
import { getDatabase, ref, onValue } from "firebase/database";
import { app } from "../Firebase";
import { Link, useParams } from "react-router-dom";

function NewsTemp() {
  const [blogData, setBlogData] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    const db = getDatabase(app);
    const blogRef = ref(db, `Blogs/${id}`);

    // Retrieve data and calculate total number of entries
    onValue(blogRef, (snapshot) => {
      const data = snapshot.val();
      setBlogData(data);
    });
  }, [id]);

  // console.log(blogData)

  return (
    <div className='blogtemp'>
      <div className="content-width">
        <div className="topbox">
          <div className="imgbox bg-img-cover" style={{ background: `url("${blogData.Img}")` }}  ></div>
          <div className="detailsbox">




            <div className="date">
              <p >Published on :  </p><p className="d">{blogData.Title}</p>
            </div>

            <Link to="/News">All News</Link>
          </div>
        </div>

        <div className="content">
          <h1>
            {blogData.Date}
          </h1>

          <div className="line"></div>
          <p dangerouslySetInnerHTML={{ __html: blogData.Content }} />
        </div>
      </div>
    </div>
  )
}

export default NewsTemp
