import React from "react";
import "../styles/contact.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

function Contact(props) {
  return (
    <>
      {props.data.map((data) => {
        return (
          <>
            <div className="contact-parent-s2 parent">
              <div className="contact-container-s2 container">
                <div className="contact-top">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    pagination={{
                      clickable: true,
                    }}
                    breakpoints={{
                      "@0.00": {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                      "@0.75": {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      "@1.00": {
                        slidesPerView: 3,
                        spaceBetween: 40,
                      },
                      "@1.50": {
                        slidesPerView: 4,
                        spaceBetween: 50,
                      },
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                  >
                    <SwiperSlide className="swiper-slide-img1 bg-img-cover "></SwiperSlide>
                    <SwiperSlide className="swiper-slide-img2 bg-img-cover"></SwiperSlide>
                    <SwiperSlide className="swiper-slide-img3 bg-img-cover"></SwiperSlide>
                    <SwiperSlide className="swiper-slide-img4 bg-img-cover"></SwiperSlide>
                  </Swiper>
                </div>

                <div className="contact-bottom">
                  <div className="broucher-left">
                    <h4>{data.Contact_brochure_title}</h4>
                    <p>{data.Contact_brochure_para}</p>

                    <div className="broucher-left-card">
                      <div className="broucher-backgoud-img bg-img-cover"></div>
                      <div className="broucher-content">
                        <h5>
                          {data.Contact_brochure_E_}
                          <br />
                          {data.Contact_brochure_E_br}
                        </h5>
                        <a href="https://drive.google.com/file/d/1AtJbf7xXjKXuH09gItgXDwcZT5Nr3aIn/view?usp=sharing" target="_blank">
                          <button className="btn-active">
                            {data.Contact_button}
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="virtual-right">
                    <h4>{data.Contact_virtual_title}</h4>
                    <p>{data.Contact_virtual_para}</p>
                    <div className="broucher-backgoud-img">
                      <div className="coming-soon">
                        <h3>{data.Contact_Coming_Soon}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
}

export default Contact;
