import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
// import { Pagination } from 'swiper/modules';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import '../styles/Features1.scss'
import { TiHomeOutline } from "react-icons/ti";
import Fea_card from '../components/Fea-card';

function New_features(props) {

    return (
        <>
        {props.data.map((data) => {
        return (

          <>


            <div className=" fea-parent">

                <Swiper
                    spaceBetween={0}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="fea-Swiper"
                >

                    <div className="fea-swiper-parent">

                        <SwiperSlide>
                            <div className='fea-overlay'></div>
                            <div className='fea-slide1 fea-slide-img' >

                                <div className="fea-slide1-text-global ">
                                    <h2>{data.Features_slider1}
                                    </h2>
                                </div>

                            </div>
                        </SwiperSlide>


                        <SwiperSlide>
                            <div className='fea-overlay'></div>
                            <div className='fea-slide2 fea-slide-img' >

                                <div className="fea-slide1-text-global ">
                                    <h2>{data.Features_slider2}

                                    </h2>
                                </div>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='fea-overlay'></div>
                            <div className='fea-slide3 fea-slide-img' >

                                <div className="fea-slide1-text-global ">
                                    <h2>{data.Features_slider3}
                                    </h2>
                                </div>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='fea-overlay'></div>
                            <div className='fea-slide4 fea-slide-img' >

                                <div className="fea-slide1-text-global ">
                                    <h2>{data.Features_slider4}
                                    </h2>
                                </div>
                            </div>
                        </SwiperSlide>
                    </div>
                </Swiper>
            </div>





            {/* //   ---------- ---------- feature-card---------------- -----------------*/}


            {/* <div className="fea-card-parent parent">
            <h2 className='fea-card-top-headding'>{data.Features_card_title}</h2>
                <div className="fea-card-container container">
                 
                    
                <div className="card-box">
                  <div className="card-img1 bg-img-contain"></div>
                  <div className="card-text">{data.Features_card1}</div>
                </div>
                <div className="card-box">
                  <div className="card-img2 bg-img-contain"></div>
                  <div className="card-text">{data.Features_card2}</div>
                </div>
                <div className="card-box">
                  <div className="card-img3 bg-img-contain"></div>
                  <div className="card-text">{data.Features_card3}</div>
                </div>
                <div className="card-box">
                  <div className="card-img4 bg-img-contain"></div>
                  <div className="card-text">{data.Features_card4}</div>
                </div>
                <div className="card-box">
                  <div className="card-img5 bg-img-contain"></div>
                  <div className="card-text">{data.Features_card5}</div>
                </div>
                <div className="card-box">
                  <div className="card-img6 bg-img-contain"></div>
                  <div className="card-text">{data.Features_card6}</div>
                </div>
              </div>
            </div> */}

        
        
            <Fea_card openPop={props.openPop} data={props.data} />


            </>
              );
            })}

        </>
    )
}

export default New_features