import React from 'react'
import News1 from '../components/News1'
import Topheading from '../components/Topheading'
import '../styles/News1.scss'

function News(props) {
  
  
 return (
  <>
  {props.data.map((data) =>{
   return(
    <>


   <div className="newsdiv">
   <Topheading
                // h5={data.News_title}
                h1={data.News_sub_title} />
   </div>





    <News1 data={props.data} 
    p= 'Lorem ipsum dolor sit ameta aa consectetur adipisicing elit.Lorem ipsum dolor sit ameta aa consectetur adipisicing elit.Lorem ipsum dolor sit ameta aa consectetur adipisicing elit.Lorem ipsum dolor sit ameta aa consectetur adipisicing elit.'/>

   

    

    




    
    
    
    
    
    </>
         );
        })}
           </>
  )
}

export default News