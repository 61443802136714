import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { MdOutlineStarRate } from "react-icons/md";
import "../styles/Marquee.scss";
import HeroSection from "../components/HeroSection";
import { getDatabase, onValue, ref as databaseRef } from "firebase/database";
import { app } from "../Firebase";

function Home(props) {
  const marqueeRef = useRef(null);

  const [newsData, setNewsData] = useState([]);
  const [latestNews, setLatestNews] = useState(3);

  const handleMouseOver = () => {
    if (marqueeRef.current) {
      marqueeRef.current.stop();
    }
  };

  const handleMouseOut = () => {
    if (marqueeRef.current) {
      marqueeRef.current.start();
    }
  };

  useEffect(() => {
    const db = getDatabase(app);
    const newsRef = databaseRef(db, "Blogs");

    onValue(newsRef, (screenshot) => {
      const data = screenshot.val();
      setNewsData(data);
    });
  }, []);

  console.log(newsData);

  return (
    <>
      <div className="marquee-container">
        <Link className="marq" to="/news">
          {" "}
          <marquee
            ref={marqueeRef}
            behavior=""
            scrollamount="10"
            direction=""
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
          

            {newsData ? (
              <>
                {Object.entries(newsData).map(([key, news]) => (
                  
                  <span className="top-p">   <MdOutlineStarRate /> {news.Date} </span>
                ))}
              </>
            ) : (
              <span></span>
            )}
          </marquee>
        </Link>
      </div>

      <HeroSection data={props.data} />
    </>
  );
}

export default Home;
