import React from 'react'
import "../../styles/togglebtn.scss"
import img2 from "../../assets/toggle-btn.png"
import { HiMiniLanguage } from "react-icons/hi2";
const Togglebtn = (props) => {
    return (
        <div className='toolbox'>
            <button onClick={props.toggle}>
                <div className='img bg-img-contain' > </div>
            </button>
        </div>
    )
}

export default Togglebtn