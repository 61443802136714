import React from 'react'
import '../styles/List.scss'
import { FaCheck } from "react-icons/fa6";


function List(props) {
  return (
    <>
       {props.data.map((data) =>{
        return(
    <>
    <div className="list parent">
        <div className="list-container container">
            <div className="list-content-box">
                <div className="list-left">
                    
                    <ul>
                        <h4>{props.title}</h4>
                        {props.li1 && <li><span className="icon"><FaCheck /></span>{props.li1}</li>}
                        {props.li2 && <li><span className="icon"><FaCheck /></span>{props.li2}</li>}
                        {props.li3 && <li><span className="icon"><FaCheck /></span>{props.li3}</li>}
                        {props.li4 && <li><span className="icon"><FaCheck /></span>{props.li4}</li>}
                        {props.li5 && <li><span className="icon"><FaCheck /></span>{props.li5}</li>}
                        {props.li6 && <li><span className="icon"><FaCheck /></span>{props.li6}</li>}
                        {props.li7 && <li><span className="icon"><FaCheck /></span>{props.li7}</li>}
                        {props.li8 && <li><span className="icon"><FaCheck /></span>{props.li8}</li>}
                        {props.li9 && <li><span className="icon"><FaCheck /></span>{props.li9}</li>}
                        {props.li10 && <li><span className="icon"><FaCheck /></span>{props.li10}</li>}
                        <button className='btn-active' onClick={props.open}>{data.Partner_with_us}</button>

                    </ul>
                    
                </div>
                <div className="list-right">
                    <div className="img-box">
                        <div className="img bg-img-cover"
                         style={{background: `url(${props.img})`}}>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>



    </>
        );
    })}

    </>
  )
}

export default List