import React from 'react'
import '../styles/about.scss';
import { TbHomeHeart } from "react-icons/tb";
import { Link } from 'react-router-dom';
// import { TiHomeOutline } from "react-icons/ti";


function About(props) {
    return (


        <>
         {props.data.map((data) =>{
        return(


        <>

            <div className="about-parent-top parent">

                <div className="about-container-top container">

                    <div className="about-top-left">
                        
                    

                        <div className="about-top-img-box bg-img-cover" ></div>
                    </div>


                    <div className="about-top-right">
                        <p>{data.About_section1}</p>
                    </div>

                </div>
            </div>






            {/* ----------about-middle-section------------ */}

            <div className="about-parent-middle parent">
                <div className="about-container-middle container">
                    <div className="about-middle-left">
                        <p>{data.About_section2_i}</p>
                        <p>{data.About_section2_ii} </p>


                    </div>
                    <div className="about-middle-right">
                        <div className='middle-right-img bg-img-cover'></div>


                    </div>
                </div>
            </div>



            {/* {/* ----------about-last-section------------ * */}


            <div className="about-last-parent parent">
                <div className="about-last-container container">
                    <div className="about-last-left">
                        <div className='about-last-left-img bg-img-cover'></div>

                    </div>

                    <div className="about-last-right">
                        <p>{data.About_section3_i}
                        </p>
                        <p>{data.About_section3_ii} </p>
                    </div>
                </div>
            </div>

        </>
              );
            })}

        </>
    )
}

export default About