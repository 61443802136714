import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import {getDatabase, onValue, ref as databaseRef } from "firebase/database";


// Import Swiper styles
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "../styles/News1.scss";
import { app } from "../Firebase";
import { Link } from "react-router-dom";
function News1(props) {
const [newsData, setNewsData ] = useState([]);
const[latestNews ,setLatestNews] = useState(3);

useEffect(() => {

  const db = getDatabase(app);
  const newsRef = databaseRef(db, "Blogs");

  onValue(newsRef, (screenshot) =>{
    const data = screenshot.val();
    setNewsData(data);
  });



},[]);

// console.log(newsData)



  return (
    <>
      {props.data.map((data) => {
        return (
          <>
            <div className="news parent">
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: true,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >

               {newsData ? (  <>{
                  Object.entries(newsData).reverse().slice(0,3).map(([key,news])=>(
                    
                <SwiperSlide key={key}>
                <div className="news parent">
                  <div className="news-container container">
                    <div className="news-content-box">
                      <div className="new">
                        <p>{data.News_latest}</p>
                      </div>
                      <div className="news-left">
                        <div className="news-img">
                          <div className="img bg-img-cover" style={{background : `url("${news.Img}")`}}   ></div>
                        </div>
                      </div>
                      <div className="news-right">
                        <div className="content">
                        <p style={{color: "var(--dark-red)"}}>{news.Title}</p>
                        {/* <p>{news.Id}</p> */}
                          <h5>{news.Date.slice(0,60)}{news.Date.length > 60  && <span>...</span>}</h5>
                          <p dangerouslySetInnerHTML={{ __html: news.Content.slice(0,50) }} />

                          <Link className="btn-active" to={`/news/${news.Id}`} >
                            {data.News_read}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
                  ))
                }
                </>) : <p>no data found</p> }
             
              </Swiper>
              <div className="newsp">
                <h2>{data.News_other}</h2>
                
                {newsData ? (  <>{
                  Object.entries(newsData).reverse().map(([key,news])=>(
                    
                    <div className="news parent" key={key}>
                    <div className="news-container container">
                      <div className="news-content-box">
                        
                        <div className="news-left">
                          <div className="news-img">
                            <div className="img bg-img-cover" style={{background : `url("${news.Img}")`}}   ></div>
                          </div>
                        </div>
                        <div className="news-right">
                          <div className="content">
                          <p style={{color: "var(--dark-red)"}}>{news.Title}</p>
                          <h5>{news.Date.slice(0,60)}{news.Date.length > 60  && <span>...</span>}</h5>
                          {/* <p>{news.Content.slice(0,200)} {news.Content.lenght > 200  && <span>...</span>} </p> */}
                          <p dangerouslySetInnerHTML={{ __html: news.Content.slice(0,50) }} />
                          <Link className="btn-active" to={`/news/${news.Id}`} >
                            {data.News_read}
                          </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  ))
                }
                </>) : <p>no data found</p> }
                    
           
               


              </div>
            </div>
          </>
        );
      })}
    </>
  );
}

export default News1;
