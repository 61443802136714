import React from 'react'

// import '../styles/Cdb.scss';
// import Download from '../components/Download'
// import Topheading from '../components/Topheading'
// import List from '../components/List'
import List from './List';
import img1 from "../Images/features/gym.jpg"
import img2 from "../Images/features/basketball.jpg"
import img3 from "../Images/features/facility.jpg"
import img4 from "../Images/features/promising.jpg"
import img5 from "../Images/features/economy.jpg"
import img6 from "../Images/features/fea-img-6.png"
import img7 from "../Images/features/fea-play.jpg"





function Fea_card(props) {

    return (
        <>
            {props.data.map((data) => {
                return (
                    <>

                        {/* <Topheading
                h5={data.cbdHeading}
                h1={data.cbd_h2} /> */}
                        <List
                            open={props.openPop}
                            data={props.data}
                            img={img6}
                            title={data.Features_ul6_title}
                            li1={data.Features_ul6_li1}
                            li2={data.Features_ul6_li2}
                            li3={data.Features_ul6_li3}
                            li4={data.Features_ul6_li4}
                            li5={data.Features_ul6_li5}
                        />
                        <List open={props.openPop}
                        data={props.data}
                            img={img7}
                            title={data.Features_ul7_title}
                            li1={data.Features_ul7_li1}
                            li2={data.Features_ul7_li2}
                            li3={data.Features_ul7_li3}
                            li4={data.Features_ul7_li4}
                            li5={data.Features_ul7_li5}
                            li6={data.Features_ul7_li6}
                        />
                              <List open={props.openPop}
                              data={props.data}
                            img={img3}
                            title={data.Features_ul3_title}
                            li1={data.Features_ul3_li1}
                            li2={data.Features_ul3_li2}
                            li3={data.Features_ul3_li3}
                            li4={data.Features_ul3_li4}

                        />

                        <List open={props.openPop}
                        data={props.data}
                            img={img2}
                            title={data.Features_ul2_title}
                            li1={data.Features_ul2_li1}
                            li2={data.Features_ul2_li2}
                            li3={data.Features_ul2_li3}
                            li4={data.Features_ul2_li4}
                            li5={data.Features_ul2_li5}
                            li6={data.Features_ul2_li6}
                            li7={data.Features_ul2_li7}
                            li8={data.Features_ul2_li8}
                        />

                        <List
                            open={props.openPop}
                            data={props.data}
                            img={img1}
                            title={data.Features_ul1_title}
                            li1={data.Features_ul1_li1}
                            li2={data.Features_ul1_li2}
                            li3={data.Features_ul1_li3}
                            li4={data.Features_ul1_li4}
                            li5={data.Features_ul1_li5}
                            li6={data.Features_ul1_li6}
                            li7={data.Features_ul1_li7}
                            li8={data.Features_ul1_li8}
                            li9={data.Features_ul1_li9}
                        />


                  

                        <List open={props.openPop}
                        data={props.data}
                            img={img4}
                            title={data.Features_ul4_title}
                            li1={data.Features_ul4_li1}
                            li2={data.Features_ul4_li2}
                            li3={data.Features_ul4_li3}
                            li4={data.Features_ul4_li4}

                        />



                        <List open={props.openPop}
                        data={props.data}
                            img={img5}
                            title={data.Features_ul5_title}
                            li1={data.Features_ul5_li1}
                            li2={data.Features_ul5_li2}
                            li3={data.Features_ul5_li3}
                            li4={data.Features_ul5_li4}

                        />
                    </>
                );
            })}


        </>

    );
}

export default Fea_card