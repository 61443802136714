import React, { useState } from "react";
import { GrClose } from "react-icons/gr";
import {getDatabase, ref, set, push } from "firebase/database";
import { app } from "../Firebase";
import { notification } from 'antd';
// import axios from "axios";
import "../styles/pop.scss";

function Pop(props) {
  const [dropdown, setDropDown] = useState(false);
  const [pop, setPop] = useState(false);
  const [name, setname] = useState("");
  const [address, setaddress] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [buying, setbuying] = useState("Residential");
  const [query, setquery] = useState("");
  const [api, contextHolder] = notification.useNotification();

// console.log(name, address, email, phone, buying, query);

  const onSubmitHandler =(e) => {
    e.preventDefault();
    const db=getDatabase(app);
    const contactsRef= ref(db,"Contacts");
    const newContactRef = push(contactsRef);
    set(newContactRef, {
      id: newContactRef.key,
      Client_Name:name,
      Client_Address:address,
      Client_Phone:phone,
      Client_Email:email,
      Client_Message:query,
      Interested_In:buying,

    });
    const openNotificationWithIcon = () => {
      api.success({
        message: "Message sent successfully!",
        description: "Thank you for contacting us",
      });
    };
    openNotificationWithIcon();

 // Clear input fields after submission
 setname("");
 setemail("");
 setphone("");
 setaddress("");
 setquery("");
//  props.closePop

  };

  return (
    <>
    {contextHolder}
      {props.data.map((data) => {
        return (
          <>
            <div className="background">
              <div className="pop-box">
                <div className="head">
                  <h2>{data.Enquiry_heading}</h2>
                  <div className="line"></div>
                  <div className="close" onClick={props.closePop}>
                    <GrClose />
                  </div>
                </div>

                <div className="main">
                  <div className="form-parent">
                    <form className="form" onSubmit={onSubmitHandler}>
                      <div className="form-group">
                        <label>{data.Enquire_name}</label>
                        <input
                          className="form-input"
                          onChange={(e)=>setname(e.target.value)}
                          name="name"
                          value={name}
                        />
                      </div>
                      <div className="form-group">
                        <label>{data.Enquire_address}</label>
                        <input
                          className="form-input"
                          onChange={(e)=>setaddress(e.target.value)}
                          name="address"
                          value={address}
                        />
                      </div>
                      <div className="form-group">
                        <label>{data.Enquire_mobile}</label>
                        <input
                          className="form-input"
                          onChange={(e)=>setphone(e.target.value)}
                          name="mobileno"
                          value={phone}
                    
                        />
                      </div>
                      <div className="form-group">
                        <label>{data.Enquire_email}</label>
                        <input
                          className="form-input"
                          onChange={(e)=>setemail(e.target.value)}
                          name="email"
                          value={email}
                          
                        />
                      </div>
                      <div className="form-group">
                        <label>{data.Enquire_interested}</label>
                        <select
                          className="form-input"
                          onChange={(e)=>setbuying(e.target.value)}
                          name="buying"
                          value={buying}
                          placeholder={data.Enquire_interested}
                          onClick={() => setDropDown(!dropdown)}
                          required
                        >
                          <option value={data.Enquire_residential} selected>
                            {data.Enquire_residential}
                          </option>
                          <option value= {data.Enquire_commercial}>
                            {data.Enquire_commercial}
                          </option>
                          <option value={data.Enquire_both_R}>
                            {data.Enquire_both_R}
                          </option>
                          <option value={data.Enquire_other}>{data.Enquire_other}</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label>{data.Enquire_comment}</label>
                        <textarea
                          className="form-input"
                          onChange={(e)=>setquery(e.target.value)}
                          name="query"
                          value={query}
                        
                        />
                      </div>

                      <div className="button-parent">
                        <button className="btn-submit" type="submit">
                          {data.Enquire_submit}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
}

export default Pop;
