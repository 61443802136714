import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyBzBhqE406Upd2X_X9BBc7LTeHu2vXL154",
  authDomain: "sssscn-db.firebaseapp.com",
  projectId: "sssscn-db",
  storageBucket: "sssscn-db.appspot.com",
  messagingSenderId: "913093328948",
  appId: "1:913093328948:web:a3dc6c3a9d6aae41c0ca61",
  measurementId: "G-E09ZKD2ZNT",
  databaseURL: "https://sssscn-db-default-rtdb.asia-southeast1.firebasedatabase.app/"
};

export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);