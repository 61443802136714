const marathi = [
    {
  

        // CBD page ==============================

        cbdHeading:"सेंट्रल बिझनेस डिस्ट्रिक्ट",

        cbd_h2:"सेंट्रल बिझनेस डिस्ट्रिक्ट",

        cbd_ul1_heading:"व्यावसायिक केंद्रे ",
        cbd_ul1_li1:"अन्न प्रक्रिया आणि पॅकेजिंग युनिट्स ",
        Cbd_ul1_li2:"शीतगृह",
        Cbd_ul1_li3:"खाजगी मार्केट यार्ड व वेअरहाऊसिंग",
        Cbd_ul1_li4:"व्यावसायिक वहन आणि वाहतूक",
        Cbd_ul1_li5:"एमएसएमई (लघुउद्योग) क्लस्टर",

        cbd_ul2_heading:"शेती आणि शेतीपूरक व्यवसाय केंद्रे",
        cbd_ul2_li1:"शेती आणि शेतीपूरक व्यवसायांसाठी आवश्यक यंत्रे, वाहने, उपकरणे आणि उत्पादनाची डिलर्सशिपस् ",
        Cbd_ul2_li2:"शेतकरी उत्पादन संस्था",
        Cbd_ul2_li3:"कंत्राटी शेती केंद्र",
        // Cbd_ul2_li4:"सौर पॅनेल आणि उपकरणे पुरवठादार.",
        Cbd_ul2_li5:"पशुसंवर्धन व दुग्ध व्यवसाय केंद्र",


        cbd_ul3_heading:"शासकीय सुविधा",
        cbd_ul3_li1:"शासकीय कार्यालये ",
        Cbd_ul3_li2:"शासकीय योजना केंद्रे आणि कार्यालये",
        Cbd_ul3_li3:"महा ई सेवा केंद्र",
        Cbd_ul3_li4:"MSEB कार्यालय",


        cbd_ul4_heading:"सहयोगी विकाससाठी इको-सिस्टम",
        cbd_ul4_li1:"चेंबर ऑफ कॉमर्स",
        Cbd_ul4_li2:"बँका आणि वित्तीय संस्था",
        Cbd_ul4_li3:"प्रदर्शन आणि अधिवेशन केंद्र",
        Cbd_ul4_li4:"सीएसआर सक्षमीकरणासाठी केंद्र",

        cbd_ul5_heading:"व्यावसायिक शिक्षण",
        cbd_ul5_li1:"कौशल्य विकास आणि रोजगारक्षम शिक्षण",
        Cbd_ul5_li2:"संस्कृती आणि ललित कला अकादमी",
        Cbd_ul5_li3:"नाट्यप्रशिक्षण केंद्र व स्कूल ऑफ ड्रामा",
        Cbd_ul5_li4:"स्पर्धा प्रवेश परीक्षा मार्गदर्शन केंद्रे",
        Cbd_ul5_li5:"उद्योजकता विकास केंद्र",

        cbd_ul6_heading:"इतर सुविधा",
        cbd_ul6_li1:"मंगल कार्यालय",
        Cbd_ul6_li2:"सुपरमार्केट",
        Cbd_ul6_li3:"हॉटेल्स, लॉजिंग",
        Cbd_ul6_li4:"मनोरंजन",


        // Home page ====================

        Home_slide1:'हो, इथे शांती आणि समृद्धी दोन्ही एकत्र नांदतात!',
        Home_slide2:'योग्य गुंतवणूक तीच जी भविष्यात परतावा देईल',
        Home_slide3:'शहर असे जे तुमच्या मुलांचे भविष्य घडवेल',
        Home_slide4:'उच्च दर्जाची आरोग्य सुविधा तुमच्या हाकेच्या अंतरावर',
        Home_slide5:'हिरवळीने नटलेले पर्यावरणपूरक शहर! ',
        Home_slide6:'तुमच्या आर्थिक विकासासाठी, ‘आमची गॅरंटी’',

        Home_card1:'निवासी',
        Home_card2:'व्यावसायिक',
        Home_card3:'शाळा',
        Home_card4:'हॉस्पिटल',
        Home_card5:'पर्यावरण',
        Home_card6:'आर्थिक विकास',

         // partner==========with====us
        Partner_with_us:'आमचे सहकारी',




        // download phases==================

        Download_:'डाउनलोड',
        Download_city:'शहराचा मुख्य आराखडा',
        Download_phase1:'फेज I',
        Download_phase2:'फेज II',
        Download_phase3:'फेज III',
        Download_phase4:'फेज IV',

        // About page ========================

        About_section1:'‘श्री. स्वामी समर्थ स्मार्ट सिटी – निलंगा’ ही ग्रामीण भागातील भारतातील पहिली नियोजित स्मार्ट सिटी आहे. भारतातील विकसनशील शहरे आणि ग्रामीण शहरांमध्ये राहणाऱ्या लोकांच्या जीवनात बदल घडवून आणण्यासाठी शाश्वत, सुरक्षित आणि स्मार्ट शहरे विकसित करण्यात अग्रेसर असलेल्या मेसर्स एनव्हीएम इन्फ्राटेक प्रायव्हेट लिमिटेडद्वारे विकसित केली जाणारी ही एकात्मिक टाऊनशिप आहे',

        About_section2_i:'तब्बल ५० एकरहून अधिक जागेवर साकारत असलेली ‘‘श्रीस्वामी समर्थ स्मार्ट सिटी – निलंगा’. लातूर जिल्ह्यातील निलंगा शहर बस स्थानकापासून अंदाजे ५ किमी अंतरावर व राष्ट्रीय महामार्ग NH 752K च्या अगदीजवळ असलेल्या हाय ग्रोथ कॉरिडॉरमध्ये उभारली जात आहे',
        About_section2_ii:'सुनियोजित शहर रचना, नियमित वीज व स्वच्छ पाण्याचा पुरवठा, सांडपाणी व्यवस्थापन, हायस्पीड इंटरनेट आणि ब्रॉडबँड अक्सेस, सुरक्षेसह जागतिक दर्जाच्या पायाभूत सुविधा आणि सर्व सोयीसुविधांनी युक्त निवासी, व्यावसायिक तसेच औद्योगिक गुंतवणुकीसाठी हे एका आदर्श असे स्थान असून आपल्या भविष्यातील विकासाला सुनिश्चित करणारी सुवर्णसंधी आहे ',
        
        About_section3_i:'निसर्गरम्य वातावरणात आणि प्रकृतीच्या कुशीत वसलेले हे शहर येथील नागरिकांना व त्यांच्या कुटुंबियांना पर्यावरणपूरक तसेच उच्चदर्जाची जीवनशैली जगण्याची संधी देते   ',
        About_section3_ii:'याचबरोबर हे शहर स्थानिक आणि राष्ट्रीय अर्थव्यवस्थेला गती देणाऱ्या व्यावसायिक उपक्रमांमध्ये सहभागी होण्यासाठी एक उत्कृष्ट व्यासपीठ प्रदान करेल. ज्यातून रोजगार आणि उद्योजकतेसाठी भरपूर संधी उपलब्ध होऊन, या क्षेत्राचा सर्वांगीण विकासचे ग्रोथ इंजिन म्हणून हे शहर भविष्यात कार्य करेल',

        // Header page====================

        Header_home:"होम",
        Header_about:"आमच्याबद्दल",
        Header_features:"वैशिष्ट्ये",
        Header_configuration:"आमच्याबद्दल",
        Header_news:"बातम्या",

        // Features page==================

        Features_slider1:'भारतातील पहिली तालुकास्तरिय स्मार्ट सिटी ',
        Features_slider2:'५० एकर क्षेत्रातील सर्व सोयी सुविधायुक्त भव्य प्रकल्प ',
        Features_slider3:'दुतर्फा झाडे असलेले दर्जेदार व प्रशस्त सिमेंट कॉंक्रिट रोड ',
        Features_slider4:'सुरक्षित व नियंत्रित सिंगल गेट एंट्रएंन्स',
        

        Features_card_title:'सुंदर व सुनियोजित ऍमिनिटीज्',

        Features_card1:'सुंदर लँडस्केप गार्डन आणि भरपूर मोकळी जागा',
        Features_card2:'भव्य आणि सुंदर मंदिर',
        Features_card3:'सोसायटीच्या कार्यक्रमांसाठी आकर्षक क्लबहाऊस',
        Features_card4:'लहान मुलांसाठी खेळाचे मैदान',
        Features_card5:'ज्येष्ठ नागरिकांसाठी विशेष निगा ',
        Features_card6:'निरोगी, हवेशीर वातावरण',
        Features_card7:'इको फ्रेंडली वॉकिंग ट्रॅक',

        Features_ul1_title:'इनडोअर क्रीडा मैदान ',
        Features_ul1_li1:'जिम ',
        Features_ul1_li2:'टेबल टेनिस ',
        Features_ul1_li3:'बॅडमिंटन कोर्ट',
        Features_ul1_li4:'कॅरम',
        Features_ul1_li5:'चेस',
        Features_ul1_li6:'स्नूकर',
        Features_ul1_li7:'योगा',
        Features_ul1_li8:'स्विमिंग पूल ',
       

        Features_ul2_title:'आऊटडोअर क्रीडा मैदान  ',
        Features_ul2_li1:'फुटबॉल ',
        Features_ul2_li2:'कब्बडी',
        Features_ul2_li3:'लॉन टेनिस ',
        Features_ul2_li4:'बास्केट बॉल ',
        Features_ul2_li5:'जिम्नॅस्टिक्स',
        Features_ul2_li6:'स्केटिंग  ',
        Features_ul2_li7:'वॉली बॉल ',
        Features_ul2_li8:'क्रिकेट',

        Features_ul3_title:'गृहस्थांसाठी अत्याधुनिक व आरामदायी सुविधा ',
        Features_ul3_li1:'भूमिगत पद्धतीने नियमित व अखंडितपणे वीज, सांडपाणी विल्हेवाट, पाणी व एलपीजी पुरवठा',
        Features_ul3_li2:'हायस्पीड WIFI आणि ब्रॉडबँड सुविधा  ',
        Features_ul3_li3:'रेनवॉटर हार्वेस्टिंग  ',
        Features_ul3_li4:'आपत्ती व्यवस्थापन आणि भूकंपरोधक व्यवस्थेने सुसज्ज ',

        Features_ul4_title:'ब्राईट फ्युचर ग्यारंटीड  ',
        Features_ul4_li1:'दर्जेदार शिक्षणासाठी निलंगा शहरातील राष्ट्रीय दर्जाची CBSE शाळा ',
        Features_ul4_li2:'अत्याधुनिक व सर्व सुविधांनी सुसज्ज मल्टीस्पेशालिस्ट हॉस्पिटल',
        Features_ul4_li3:'इलेक्ट्रिक वाहतूक व चार्जिंग स्टेशन',
        Features_ul4_li4:'सेंट्रल बिसनेस डिस्ट्रिक्ट डेव्हलपमेंट निलंगा शहरातील पहिले असे नियोजित सर्वसमावेशक बिसनेस सेन्टर',

      
        Features_ul5_title:'वाढते शहर, गतिमान अर्थव्यवस्था! ',
        Features_ul5_li1:'आर्थिक विकासाच्या नव्या संधीचे सोने करण्यासाठी सर्वोत्तम वेळ',
        Features_ul5_li2:'भविष्यात अत्यंत वेगाने विकसित होणारे परिसर' ,
        Features_ul5_li3:'राष्ट्रीय महामार्गाला लागून असे बिसनेस डिस्ट्रिक्ट',
        Features_ul5_li4:'हेलिपॅड',


        Features_ul6_title:'प्रशस्त आणि सुरक्षित ',
        Features_ul6_li1:'भारतातील पहिली तालुकास्तरिय स्मार्ट सिटी ',
        Features_ul6_li2:'५० एकर क्षेत्रातील सर्व सोयी सुविधायुक्त भव्य प्रकल्प ' ,
        Features_ul6_li3:'दुतर्फा झाडे असलेले दर्जेदार व प्रशस्त सिमेंट कॉंक्रिट रोड ',
        Features_ul6_li4:'सीसीटीव्ही निगराणीखाली, सुरक्षित व नियंत्रित सिंगल गेट एंट्रएंन्स ',
        Features_ul6_li5:'केंद्रित नियंत्रण कक्ष ',


        Features_ul7_title:'सुंदर व सुनियोजित ऍमिनिटीज्',
        Features_ul7_li1:'सुशोभित गार्डन आणि ओपन स्पेस',
        Features_ul7_li2:'भव्य मंदिर सोसायटी इव्हेंट किंवा कार्यक्रमासाठी सुसज्ज असे क्लब हाउस  ' ,
        Features_ul7_li3:'लहान मुलांसाठी दर्जेदार ग्राउंड आणि प्ले एरिया',
        Features_ul7_li4:'जेष्ठ नागरिकांसाठी विशेष सुविधा  ',
        Features_ul7_li5:'नॅच्युरल व्हेंटिलेशन असलेले उत्तम व आरोग्यदायी वातावरण  ',
        Features_ul7_li6:'भव्य आणि निसर्गरम्य वॉकिंग ट्रॅक   ',


        // Residencial page ===========================

        Residencial_phase1_title:'फेज 1',
        Residencial_phase2_title:'फेज 2',
        Residencial_phase3_title:'फेज 3',
        Residencial_phase4_title:'फेज 4',
        Residencial_phase5_title:'निवासी',
        Residencial_layout:'आराखडा ',
        Residencial_configuration:'वैशिष्टे ',


        // Header page       ================================

        Header_logo:'श्री. स्वामी समर्थ स्मार्ट सिटी, निलंगा',
        Header_home:'होम',
        Header_subheading:"आपल्या मनातली स्वप्ननगरी!",
        Header_about:'आमच्याबद्दल',
        Header_features:'वैशिष्ट्ये',
        Header_residential:'निवासी',
        Header_cbd:'सेंट्रल बिझनेस डिस्ट्रिक्ट',
        Header_news:'बातम्या',
        Header_contact:'संपर्क',
        Header_client:'ग्राहक नोंदणी',
        Header_enquire:'चौकशी',
        Header_now:'करा',

        // news page==============

        News_title:'बातम्या',
        News_sub_title:'ताज्या बातम्या ',

         //  contact page=============

         Contact_site_title:'साईट ऑफिस',
         Contact_Nilangana:'निलंगा:',
         Contact_site_add:'श्री स्वामी समर्थ स्मार्ट सिटी',
         Contact_site_br:'निलंगा, लातूर- ४१३५२१',
 
         Contact_branch_title:'शाखा कार्यालय',
         Contact_latur:'लातूर:',
         Contact_branch_add:'ऑफिस नं. ९/१०, ईशान स्क्वेअर,',
         Contact_branch_br:'बार्शी रिंग रोड, लातूर-४१३५१२',
 
         Contact_head_title:'मुख्य कार्यालय',
         Contact_pune:'पुणे:',
         Contact_head_add:'१०१६, न्याती इम्प्रेस,',
         Contact_head_br:'विमान नगर, पुणे- ४११०१४ ',
        
 
         Contact_brochure_title:'डाऊनलोड इ-ब्रोशर',
         Contact_brochure_para:'ई-ब्रोशर डाऊनलोड करण्यासाठी खालील लिंकवर क्लिक करा',
         Contact_brochure_E_:'स्मार्ट सिटी निलंगा',
         Contact_brochure_E_br:'ई-ब्रोशर',
         Contact_button:'डाऊनलोड',
 
         Contact_virtual_title:'व्हर्चुअल टूर',
         Contact_virtual_para:'व्हर्चुअल टूरसाठी क्युआर कोड स्कॅन करा',
         Contact_Coming_Soon:'लवकरच येत आहे...',


         
        // enquire now pop up=========

        Enquire_name:'नाव',
        Enquiry_heading:'चौकशी करा',
        Enquire_address:'पत्ता',
        Enquire_mobile:'मोबाईल क्र. ',
        Enquire_email:'इमेल आयडी',
        Enquire_interested:'खरेदी करण्यास इच्छुक  ',
        Enquire_residential:'निवासी',
        Enquire_commercial:'व्यावसायिक',
        Enquire_both_R:'निवासी आणि व्यावसायिक दोन्ही ',
        Enquire_other:'इतर',
        Enquire_comment:'प्रतिक्रिया',
        Enquire_submit:'जमा करा',

        // footer  ==============================

        Footer_logo:'श्री स्वामी समर्थ स्मार्ट सिटी, निलंगा ',
        Footer_mob_no:'+९१ 7050705078',

        Footer_site_:"साईट ऑफिस",
        Footer_site_ad:"निलंगा",
        Footer_site_address:"श्री स्वामी समर्थ स्मार्ट सिटी",
        Footer_site_address_br:"निलंगा, लातूर- ४१३५२१",

        Footer_branch_:"शाखा कार्यालय ",
        Footer_branch_ad:"लातूर",
        Footer_branch_address:"ऑफिस नं. ९/१०, ईशान स्क्वेअर,",
        Footer_branch_address_br:"बार्शी रिंग रोड, लातूर-४१३५१२",

        Footer_head_:"मुख्य कार्यालय",
        Footer_head_ad:"पुणे",
        Footer_head_address:"१०१६, न्याती इम्प्रेस,",
        Footer_head_address_br:"विमान नगर, पुणे- ४११०१४",

        Footer_mail:' info@smartcitynilanga.com',
       



        // ===================================

        News_read:'अधिक वाचा',
        News_other:'इतर अपडेट्स',
        News_latest:'लेटेस्ट ',

       




        






    


    },
];
export default marathi;