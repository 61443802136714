import React, { useRef, useState } from "react";
import "../styles/residencial.scss";
import { Link } from "react-router-dom";
import Topheading from "../components/Topheading";
import Download from "../components/Download";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function Residencial(props) {
  const [phase1, setPhase1] = useState(true);
  const [phase2, setPhase2] = useState(false);
  const [phase3, setPhase3] = useState(false);
  const [phase4, setPhase4] = useState(false);
  const [res, setRes] = useState(false);

  function handelClose() {
    setPhase1(false);
    setPhase2(false);
    setPhase3(false);
    setPhase4(false);
    setRes(false);
  }

  function phase1Click() {
    handelClose();
    setPhase1(true);
  }

  function phase2Click() {
    handelClose();
    setPhase2(true);
  }

  function phase3Click() {
    handelClose();
    setPhase3(true);
  }

  // function phase4Click() {
  //   handelClose();
  //   setPhase4(true);
  // }

  function resClick() {
    handelClose();
    setRes(true);
  }

  return (
    <>
      {props.data.map((data) => {
        return (
          <>
            {/* section 1 start */}
            <div className="residencial-parent-top bg-img-cover parent">
              <div className="overlay "></div>
            </div>
            {/* section 1 end */}

            {/* section 2 start*/}
            <Download
              data={props.data}
              style={{ marginTop: "0px !important" }}
            />
            <Topheading
              h1={data.Residencial_layout}
              
            />

            <div className="residencial-parent-bottom parent">
              <div className="residencial-container-bottom container">
                <div className="resi-bottom">
                  <div className="bottom-list">
                    <Link
                      className={phase1 && "active"}
                      onClick={() => phase1Click()}
                    >
                      {data.Residencial_phase1_title}
                    </Link>
                    <Link
                      className={phase2 && "active"}
                      onClick={() => phase2Click()}
                    >
                       {data.Residencial_phase2_title}
                    </Link>
                    <Link
                      className={phase3 && "active"}
                      onClick={() => phase3Click()}
                    >
                       {data.Residencial_phase3_title}
                    </Link>
                    {/* <Link
                      className={phase4 && "active"}
                      onClick={() => phase4Click()}
                    >
                       {data.Residencial_phase4_title}
                    </Link> */}
                    <Link
                      className={res && "active"}
                      onClick={() => resClick()}
                    >
                       {data.Residencial_phase5_title}
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* section 2 end*/}

            {/* section 3 start */}

            {phase1 && (
              <div className="layout-page parent">
                <div className="layout-page-box container">
                  <div className="layout-left">
                    <div className="layout-content">
                      <h3>{data.Residencial_phase1_title}</h3>
                      <p className="p-tag">
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Quaerat dolores commodi quisquam eum eaque
                        inventore odit ratione enim ducimus, adipisci quam velit
                        provident ipsum vero impedit amet. Beatae, non!
                      </p>
                    </div>
                  </div>
                  <div className="layout-right">
                    <div className="layout-img bg-img-contain r1"></div>
                  </div>
                </div>
              </div>
            )}

            {phase2 && (
              <div className="layout-page parent">
                <div className="layout-page-box container">
                  <div className="layout-left">
                    <div className="layout-content">
                      <h3>Phase 2</h3>
                      <p className="p-tag">
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Quaerat dolores commodi quisquam eum eaque
                        inventore odit ratione enim ducimus, adipisci quam velit
                        provident ipsum vero impedit amet. Beatae, non!
                      </p>
                    </div>
                  </div>
                  <div className="layout-right">
                    <div className="layout-img bg-img-contain r2"></div>
                  </div>
                </div>
              </div>
            )}

            {phase3 && (
              <div className="layout-page parent">
                <div className="layout-page-box container">
                  <div className="layout-left">
                    <div className="layout-content">
                      <h3>Phase 3</h3>
                      <p className="p-tag">
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Quaerat dolores commodi quisquam eum eaque
                        inventore odit ratione enim ducimus, adipisci quam velit
                        provident ipsum vero impedit amet. Beatae, non!
                      </p>
                    </div>
                  </div>
                  <div className="layout-right">
                    <div className="layout-img bg-img-contain r3"></div>
                  </div>
                </div>
              </div>
            )}

            {phase4 && (
              <div className="layout-page parent">
                <div className="layout-page-box container">
                  <div className="layout-left">
                    <div className="layout-content">
                      <h3>Phase 4</h3>
                      <p className="p-tag">
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Quaerat dolores commodi quisquam eum eaque
                        inventore odit ratione enim ducimus, adipisci quam velit
                        provident ipsum vero impedit amet. Beatae, non!
                      </p>
                    </div>
                  </div>
                  <div className="layout-right">
                    <div className="layout-img bg-img-contain r4"></div>
                  </div>
                </div>
              </div>
            )}

            {res && (
              <div className="layout-page parent">
                <div className="layout-page-box container">
                  <div className="layout-left">
                    <div className="layout-content">
                      <h3>Residential</h3>
                      <p className="p-tag">
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Quaerat dolores commodi quisquam eum eaque
                        inventore odit ratione enim ducimus, adipisci quam velit
                        provident ipsum vero impedit amet. Beatae, non!
                      </p>
                    </div>
                  </div>
                  <div className="layout-right">
                    <div className="layout-img bg-img-contain r5"></div>
                  </div>
                </div>
              </div>
            )}

            {/* section 3 end */}

            <div className="mob-swiper parent">
              <div className="mob-swiper-cont container">
                <Swiper
                  spaceBetween={30}
                  centeredSlides={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="swiper-top r1"></div>
                    <div className="swiper-bottom">
                    <h3>Phase 1</h3>
                      <p className="p-tag">
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Quaerat dolores commodi quisquam eum eaque
                        inventore odit ratione enim ducimus, adipisci quam velit
                        provident ipsum vero impedit amet. Beatae, non!
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-top r2"></div>
                    <div className="swiper-bottom">
                    <h3>Phase 2</h3>
                      <p className="p-tag">
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Quaerat dolores commodi quisquam eum eaque
                        inventore odit ratione enim ducimus, adipisci quam velit
                        provident ipsum vero impedit amet. Beatae, non!
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-top r3"></div>
                    <div className="swiper-bottom">
                    <h3>Phase 3</h3>
                      <p className="p-tag">
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Quaerat dolores commodi quisquam eum eaque
                        inventore odit ratione enim ducimus, adipisci quam velit
                        provident ipsum vero impedit amet. Beatae, non!
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-top r4"></div>
                    <div className="swiper-bottom">
                    <h3>Phase 4</h3>
                      <p className="p-tag">
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Quaerat dolores commodi quisquam eum eaque
                        inventore odit ratione enim ducimus, adipisci quam velit
                        provident ipsum vero impedit amet. Beatae, non!
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-top r5"></div>
                    <div className="swiper-bottom">
                    <h3>Residential</h3>
                      <p className="p-tag">
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Quaerat dolores commodi quisquam eum eaque
                        inventore odit ratione enim ducimus, adipisci quam velit
                        provident ipsum vero impedit amet. Beatae, non!
                      </p>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
}

export default Residencial;
