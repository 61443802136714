const english = [
    {
        
        //here is CBD section===================

        cbdHeading:"Central Business District",

        cbd_h2:"Central Business District",

        cbd_ul1_heading:"Commercial Activity Hub",
        cbd_ul1_li1:"Food Processing and Packaging units",
        Cbd_ul1_li2:"Cold Storage units",
        Cbd_ul1_li3:"Private Market Yard and Warehousing",
        Cbd_ul1_li4:"Logistics and Transportation",
        Cbd_ul1_li5:"MSME Cluster",

        cbd_ul2_heading:"Agriculture and agribusiness centres",
        cbd_ul2_li1:"Dealerships of machinery, vehicles, equipment and produce required for agriculture and agribusiness.",
        Cbd_ul2_li2:"Farmers Production Institute.",
        Cbd_ul2_li3:"Contract Farming Centre.",
        // Cbd_ul2_li4:"Solar panels and equipment suppliers",
        Cbd_ul2_li5:"Center for Animal Husbandry and Dairying.",

        cbd_ul3_heading:"Government Infrastructure",
        cbd_ul3_li1:"Government Buildings",
        Cbd_ul3_li2:"Government Schemes Enablement Center",
        Cbd_ul3_li3:"Maha E Seva Kendra",
        Cbd_ul3_li4:"MSEB Office",

        cbd_ul4_heading:"Collaborative and Financial Growth Eco-system",
        cbd_ul4_li1:"Chamber of Commerce",
        Cbd_ul4_li2:"Banks and Financial Institutions",
        Cbd_ul4_li3:"Exhibition and Convention Center",
        Cbd_ul4_li4:"CSR Enablement Center",

        cbd_ul5_heading:"Vocational Education",
        cbd_ul5_li1:"Culture and Fine Arts Academy",
        Cbd_ul5_li2:"Theater - School of Drama ",
        Cbd_ul5_li3:"Competitive Entrance Exam Coaching",
        Cbd_ul5_li4:"Entrepreneurship Incubation Centre",
        Cbd_ul5_li5:"Employment and Skill Development – Finishing Schools",

        cbd_ul6_heading:"Other Amenities",
        cbd_ul6_li1:"Ceremony Hall ",
        Cbd_ul6_li2:"Supermarketss ",
        Cbd_ul6_li3:"Hotels, lodgings ",
        Cbd_ul6_li4:"Amusement parks ",


        // Home page ====================

        Home_slide1:'Yes, here peace and prosperity coexist!',
        Home_slide2:'A wise investment that will yield returns in the future.',
        Home_slide3:'A city crafted with your child`s future at its heart.',
        Home_slide4:'Advanced healthcare facility at your doorstep.',
        Home_slide5:'A city embraced by nature`s green symphony!',
        Home_slide6:'Your financial development is `Our Guarantee`',

        Home_card1:'Residential',
        Home_card2:'Commercial',
        Home_card3:'School',
        Home_card4:'Hospital',
        Home_card5:'Environment',
        Home_card6:'Economic Growth',


         // download phases==================


        Download_:'Download',
        Download_city:'Entire City Layout',
        Download_phase1:'Phase I',
        Download_phase2:'Phase II',
        Download_phase3:'Phase III',
        Download_phase4:'Phase IV',

        // About page =======================

        About_section1:'“Shri. Swami Samarth Smart City – Nilanga” is India’s first planned Smart City in rural area. It is an Integrated Township being developed by M/s NVM Infratech Pvt. Ltd., a pioneer in developing sustainable, secure and smart cities to transform the lives of the people residing in developing cities and rural towns across India. ',

        
        About_section2_i:'Built across more than 50 acres of land, “Shri. Swami Samarth Smart City – Nilanga” is being developed in the city of Nilanga in Latur district of Maharashtra. The city is coming up in the high growth corridor of the area and is having direct access to National Highway NH 752K. The Smart City is approximately 5 kms from the Nilanga Bus station.  ',
        About_section2_ii:'The city boasts of a world-class infrastructure and amenities and is an ideal destination for residential as well as commercial/industrial investments. It provides benefits of a planned city development with regular power supply, clean water and sanitation, high-speed broadband access and a safe and secure surveillance-based gated community.   ',

        About_section3_i:'This city will enable residents to enjoy a high standard of living for themselves and their families in an eco-friendly environment with lots of greenery and open spaces to live as close as possible to mother nature. ',
        About_section3_ii:'The Smart City will also provide an excellent platform to engage in commercial activities based on local and national economy and provide ample opportunities for employment and entrepreneurship, thereby acting as an engine of growth for that area.',

      
        // Header page====================

         Header_home:"होम",
         Header_about:"आमच्याबद्दल",
         Header_features:"वैशिष्ट्ये",
         Header_configuration:"आमच्याबद्दल",
         Header_news:"बातम्या",

        //  Features page==================

        Features_slider1:'India`s inaugural Tier Three Smart City',
        Features_slider2:'Expansive 50-acre project with comprehensive facilities',
        Features_slider3:'Well-connected two-way gravel-grade concrete roads',
        Features_slider4:'Secured, automated single-gate entry',

        Features_card_title:'Striking Amenities',

        Features_card1:'Beautifully landscaped gardens and open spaces',
        Features_card2:'Magnificent temple',
        Features_card3:'Decorated clubhouse for society events',
        Features_card4:'Playground for children',
        Features_card5:'Special provisions for senior citizens',
        Features_card6:'Healthy, well-ventilated environment',
        Features_card7:'Eco-friendly walking track',

        Features_ul1_title:'Indoor Recreation',
        Features_ul1_li1:'Gymnasium',
        Features_ul1_li2:'Table tennis ',
        Features_ul1_li3:'Badminton courts',
        Features_ul1_li4:'Carrom board',
        Features_ul1_li5:'Chess',
        Features_ul1_li6:'Snooker/Billiards',
        Features_ul1_li7:'Yoga',
        Features_ul1_li8:'Swimming Pool',
       

        Features_ul2_title:'Outdoor Recreation Grounds   ',
        Features_ul2_li1:'Football',
        Features_ul2_li2:'Kabaddi',
        Features_ul2_li3:'Lawn Tennis',
        Features_ul2_li4:'Basketball ',
        Features_ul2_li5:'Gymnastics',
        Features_ul2_li6:'Skating ',
        Features_ul2_li7:'Volleyball',
        Features_ul2_li8:'Cricket',

        Features_ul3_title:'Modern and extensive amenities for residents.',
        Features_ul3_li1:'Modern and extensive amenities for residents.',
        Features_ul3_li2:'High-speed Wi-Fi and broadband ',
        Features_ul3_li3:'Rainwater harvesting',
        Features_ul3_li4:'Disaster management and earthquake prevention measures ',

        Features_ul4_title:'Securing Your Bright Future',
        Features_ul4_li1:'National-level CBSE school for quality education in Nilanga',
        Features_ul4_li2:'Advanced and Fully Equipped Multi-Specialty Healthcare',
        Features_ul4_li3:'E-Transportation & Charging Station',
        Features_ul4_li4:'First planned and integrated business centre in Nilanga',
      
        Features_ul5_title:'City on the Rise, Economy Thriving!',
        Features_ul5_li1:'Now is the prime time to seize new economic opportunities',
        Features_ul5_li2:'Anticipating significant development in the area ',
        Features_ul5_li3:'Business district conveniently located near the national highway',
        Features_ul5_li4:'Helipad',


        Features_ul6_title:'Spacious and secure',
        Features_ul6_li1:'First Smart City of Rural India ',
        Features_ul6_li2:'Grand 50-acres project with All-Inclusive Facilities' ,
        Features_ul6_li3:'High-quality cemented roads furnished with greenery ',
        Features_ul6_li4:'Gated community with monitored areas and CCTV surveillance',
        Features_ul6_li5:'Central command center ',

        Features_ul7_title:'Beautiful and well-planned amenities',
        Features_ul7_li1:'Beautifully landscaped gardens and open spaces',
        Features_ul7_li2:'Decorated clubhouse for society events  ' ,
        Features_ul7_li3:'High-quality ground and play area for children',
        Features_ul7_li4:'Special provisions for senior citizens ',
        Features_ul7_li5:'Healthy, well-ventilated environment ',
        Features_ul7_li6:'Eco-friendly walking track ',


        // Residencial page ===========================

        Residencial_phase1_title:'Phase1',
        Residencial_phase2_title:'Phase2',
        Residencial_phase3_title:'Phase3',
        Residencial_phase4_title:'Phase4',
        Residencial_phase5_title:'Residential',
        Residencial_layout:'Layout ',
        Residencial_configuration:'Configuration ',

        // header page=================

        Header_logo:'Shri. Swami Samarth Smart City, Nilanga',
        Header_home:'Home',
        Header_subheading:"The city of your dreams!",
        Header_about:'About us',
        Header_features:'Features',
        Header_residential:'Residential',
        Header_cbd:'Central Business District',
        Header_news:'News',
        Header_contact:'Contact us',
        Header_client:'Client Login',
        Header_enquire:'Enquire',
        Header_now:'Now',

         // news page==============

         News_title:'News',
         News_sub_title:'Latest News',
         News_latest:'New ',

        //  contact page=============

        Contact_site_title:'Site Office',
        Contact_Nilangana:'Nilanga:',
        Contact_site_add:'Shree Swami Samarth Smart City',
        Contact_site_br:'Nilanga, Latur- 413521',

        Contact_branch_title:'Branch Office',
        Contact_latur:'Latur:',
        Contact_branch_add:'Office no. 9/10, Ishaan Square, ',
        Contact_branch_br:'Baarshi Ring Road, Latur -413521',

        Contact_head_title:'Head Office',
        Contact_pune:'Pune:',
        Contact_head_add:'1016, Nyati Impress,     ',
        Contact_head_br:'Viman Nagar, Pune- 411014        ',
       

        Contact_brochure_title:'Download E-Brochure',
        Contact_brochure_para:'Click the link below to download E-brochure',
        Contact_brochure_E_:'Smart City Nilanga E-Brochure',
        Contact_button:'Download',

        Contact_virtual_title:'Virtual Tour',
        Contact_virtual_para:'Click the link below to download E-brochure',
        Contact_Coming_Soon:'Coming Soon...',

        // enquire now pop up=========

        Enquire_name:'Name',
        Enquiry_heading:'Enquire Now',
        Enquire_address:'Address',
        Enquire_mobile:'Mobile No. ',
        Enquire_email:'Email ID(Optional) ',
        Enquire_interested:'Interested in Buying ',
        Enquire_residential:'Residential',
        Enquire_commercial:'Commercial',
        Enquire_both_R:'Both Residential and Commercial ',
        Enquire_other:'Other',
        Enquire_comment:'Comments',
        Enquire_submit:'Submit',

        // partner==========with====us
        Partner_with_us:'Partner with us',


        // footer  ==============================

        Footer_logo:'Shri Swami Samarth Smart City, Nilanga ',
        Footer_mob_no:'+91 7050705078',

        Footer_site_:"Site Office ",
        Footer_site_ad:"Nilanga ",
        Footer_site_address:"Shree Swami Samarth Smart City",
        Footer_site_address_br:"Nilanga, Latur- 413521",

        Footer_branch_:"Branch Office ",
        Footer_branch_ad:"Latur ",
        Footer_branch_address:"Office no. 9/10, Ishaan Square,",
        Footer_branch_address_br:"Baarshi Ring Road, Latur -413521",

        Footer_head_:"Head Office ",
        Footer_head_ad:"Pune",
        Footer_head_address:"1016, Nyati Empress,",
        Footer_head_address_br:"Viman Nagar, Pune- 411014",

        Footer_mail:' info@smartcitynilanga.com',
        


          // ===================================

          News_read:'Read More',
          News_other:'Other Updates'

        
        
        

        
       




         

        
        
      




    },
];
export default english;