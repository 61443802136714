import React from "react";
import { Link } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { GiWireframeGlobe } from "react-icons/gi";
import { IoMdMail } from "react-icons/io";
import "../styles/Footer.scss";

function Footer(props) {
  return (
    <>
      {props.data.map((data) => {
        return (
          <>
            <div className="footer-parent parent">
              <div className="footer-container container">
                <div className="f-top-heading-box">
                  <div className="footer-headding">
                    <div className="flogo bg-img-cover"></div>

                    <h3 className="f-sssc">{data.Footer_logo}</h3>
                  </div>
                  <div className="f-icon-box">
                    <Link
                      to="https://www.facebook.com/NVMSmartCities"
                      target="_blank"
                    >
                      {" "}
                      <span className="f-icon1">
                        <FaFacebookF />
                      </span>
                    </Link>

                    <Link
                      to="https://www.instagram.com/nvmsmartcities"
                      target="_blank"
                    >
                      {" "}
                      <span className="f-icon2">
                        <FaInstagram />
                      </span>
                    </Link>

                    <Link
                      to="https://www.youtube.com/@NVMSmartCities"
                      target="_blank"
                    >
                      {" "}
                      <span className="f-icon3">
                        <FaYoutube />
                      </span>
                    </Link>
                    <Link
                      to="https://twitter.com/NvmSmartCities"
                      target="_blank"
                    >
                      {" "}
                      <span className="f-icon4">
                        <FaTwitter />
                      </span>
                    </Link>
                  </div>
                </div>

                <div className="footer-top">
                  <div className="column-pnumber">
                    <div className="icon-address ">
                      <span className="f-icon-phone glass">
                        <FaPhoneAlt />
                      </span>
                      <span>
                        <a href="tel: 7050705078">
                          <p>{data.Footer_mob_no}</p>
                        </a>
                      </span>
                    </div>
                    <div className="icon-address ">
                      <span className="f-icon1 glass">
                        <IoMdMail />
                      </span>
                      <span>
                        <a
                          className="icon-link"
                          href="mailto:info@smartcitynilanga.com"
                        >
                          {" "}
                          {data.Footer_mail}
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="column-first">
                    <h5 className="title">{data.Footer_site_}</h5>
                    <h6 className="sub-title">{data.Footer_site_ad}</h6>

                    <p>{data.Footer_site_address} {data.Footer_site_address_br}</p>

                   
                  </div>

                  <div className="column-second">
                    <h5 className="title">{data.Footer_branch_}</h5>
                    <h6 className="sub-title">{data.Footer_branch_ad}</h6>
                    <a
                      href="https://maps.app.goo.gl/W9WMm7x5GLziE9HfA"
                      target="_blank"
                    >
                      <p>{data.Footer_branch_address}<br/>{data.Footer_branch_address_br}</p>
                     
                    </a>
                  </div>

                  <div className="column-third">
                    <h5 className="title"> {data.Footer_head_}</h5>
                    <h6 className="sub-title">{data.Footer_head_ad}</h6>
                    <a
                      href="https://maps.app.goo.gl/hPwXekJ3ACWLnV2C9"
                      target="_blank"
                    >
                      <p>{data.Footer_head_address}<br/>{data.Footer_head_address_br}</p>
                    
                    </a>
                  </div>
                  <div className="column-fourth ">
                    <Link to='https://nvminfratech.com' target="_blank">
                      <div className="column-logo bg-img-contain"></div>
                    </Link>
                  </div>
                </div>

                <div className="footer-bottom">
                  {/* <div className="bottom-section"> */}
                  <p className="">©Copyrights reserved </p>
                  <Link to="/terms" className="footer-terms">Terms & Conditions</Link>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
}

export default Footer;
