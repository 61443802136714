import React from "react";
import "../styles/home_card.scss";

function Home_card(props) {
  return (
    <>
      {props.data.map((data) => {
        return (
          <>
            <div className="home-card-parent parent">
              <div className="home-card-container container">
                <div className="card-box">
                  <div className="card-img1 bg-img-contain"></div>
                  <div className="card-text">{data.Home_card1}</div>
                </div>
                <div className="card-box">
                  <div className="card-img2 bg-img-contain"></div>
                  <div className="card-text">{data.Home_card2}</div>
                </div>
                <div className="card-box">
                  <div className="card-img3 bg-img-contain"></div>
                  <div className="card-text">{data.Home_card3}</div>
                </div>
                <div className="card-box">
                  <div className="card-img4 bg-img-contain"></div>
                  <div className="card-text">{data.Home_card4}</div>
                </div>
                <div className="card-box">
                  <div className="card-img5 bg-img-contain"></div>
                  <div className="card-text">{data.Home_card5}</div>
                </div>
                <div className="card-box">
                  <div className="card-img6 bg-img-contain"></div>
                  <div className="card-text">{data.Home_card6}</div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
}

export default Home_card;
