import React from 'react'
import '../styles/HeroSection.scss';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Home_card from './Home_card';
function HeroSection(props) {
    return (
        <>
        {props.data.map((data) =>{
        return(
        <>

            <div className=" hero-parent">

                <Swiper
                    spaceBetween={0}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >

                    <div className="swiper-parent">

                        <SwiperSlide>
                            <div className='overlay'></div>
                            <div className='slide1 slide-img' >

                                <div className="slide1-text-global ">
                                    <h2>{data.Home_slide1} </h2>
                                </div>

                            </div>
                        </SwiperSlide>


                        <SwiperSlide>
                            <div className='overlay'></div>
                            <div className='slide2 slide-img ' >

                                <div className="slide1-text-global ">
                                    <h2>{data.Home_slide2} </h2>
                                </div>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='overlay'></div>
                            <div className='slide3 slide-img' >

                                <div className="slide1-text-global ">
                                    <h2>{data.Home_slide3}</h2>
                                </div>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='overlay'></div>
                            <div className='slide4 slide-img' >

                                <div className="slide1-text-global ">
                                    <h2>{data.Home_slide4}</h2>
                                </div>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='overlay'></div>
                            <div className='slide5 slide-img' >

                                <div className="slide1-text-global ">
                                    <h2>{data.Home_slide5} </h2>
                                </div>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='overlay'></div>
                            <div className='slide6 slide-img' >

                                <div className="slide1-text-global ">
                                    <h2>{data.Home_slide6} </h2>
                                </div>

                            </div>
                        </SwiperSlide>

                    </div>




                </Swiper>


            </div>
            <Home_card data={props.data}/>

        </>
        );
    })}

        </>
    )
}

export default HeroSection